<template>
  <v-container>
    <v-row no-gutters class="ma-0">
      <v-col cols="12">
        <v-list
          two-line
        >
          <template v-for="(item, index) in notifications">
            <v-list-item
              :key="`notification-${index}`"
              @click="openNotification(item)"
            >
              <v-list-item-avatar>
                <v-icon v-if="item.unread">
                  $message
                </v-icon>
                <v-icon v-else>
                  $openedMessage
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.verb"></v-list-item-title>

                <v-list-item-subtitle class="caption">
                  {{ formatDate(item.timestamp) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`divider-${index}`"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog v-model="notificationDialog">
      <v-card>
        <v-card-title
          class="subtitle-1 grey lighten-4 d-block"
        >
          <div style="word-break: break-word; color: #7a003c;">
            {{ notification.verb }}
          </div>
          <div class="caption">{{ formatDate(notification.timestamp) }}</div>
        </v-card-title>
        <v-card-text class="text--primary py-2" v-html="notification.description">
          {{ notification.description }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="notification.attachment !== null"
            color="primary"
            text
            @click="downloadAttachment(notification.attachment)"
          >
            Attachment
            <v-icon>$downloadAttachment</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeNotificationDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "Messages",
  data() {
    return {
      headers: [
        {
          text: 'Read',
          align: 'start',
          sortable: false,
          value: 'unread'
        },
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'timestamp'
        },
        {
          text: 'Title',
          align: 'start',
          sortable: false,
          value: 'verb'
        }
      ],
      notificationDialog: false,
      notification: {
        verb: '',
        description: '',
        timestamp: null
      }
    }
  },
  computed: {
    ...mapState('user', ['notifications']),
  },
  watch: {
    '$route.query': {
      handler(value) {
        if (value.message_id && this.notifications.length) {
          const notificationToOpen = this.notifications
            .find((notif) => notif.id === parseInt(value.message_id, 10));
          this.openNotification(notificationToOpen)
        }
      },
      immediate: true
    }
  },
  methods: {
    openNotification(item) {
      this.notification = item;
      this.notificationDialog = true;
      if (item.unread) {
        this.$store.dispatch('user/markNotificationAsRead', item.id);
      }
    },
    downloadAttachment(attachment) {
      axios({
        url: attachment.url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', attachment.name);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    formatDate(date) {
      const dateObject = new Date(date);
      return dateObject.toLocaleString('sl-SL');
    },
    closeNotificationDialog() {
      this.notificationDialog = false;
      this.notification = {
        verb: '',
        description: '',
        timestamp: null
      };
      this.$router.replace({ query: null }).catch(() => {});
    }
  }
}
</script>

<style scoped>

</style>
