<template>
  <v-container fluid class="vertical-center">
    <ValidationObserver ref="obs" v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(resetPassword)"
              class="row row--dense justify-center align-center">
        <v-col cols="12">
          <div class="title text-center">
            {{ $t('SetNewPassword.title') }}
          </div>
        </v-col>
        <v-col cols="10" sm="12">
          <v-col cols="12">
            <div class="text-center text--secondary">
              {{ $t('SetNewPassword.message') }}
            </div>
          </v-col>
          <v-col
            v-for="(field) in inputFields"
            :key="field.name"
            cols="12" sm="6" class="mx-auto pa-0"
          >
            <TextFieldWithValidation
              v-model="field.model"
              :name="field.name"
              outlined
              dense
              mode="eager"
              :label="field.label"
              :rules="field.rules"
              :type="field.type"
            />
          </v-col>
        </v-col>
        <v-col cols="10" sm="6" md="4" class="text-center">
          <v-btn rounded color="secondary" type="submit" :loading="loading"
          >{{ $t('SetNewPassword.confirmNewPassword') }}
          </v-btn
          >
        </v-col>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
export default {
  name: 'SetNewPassword',
  data() {
    return {
      inputFields: [
        {
          name: 'password-reset',
          model: '',
          rules: 'required|min:10|password_complexity',
          label: this.$t('inputLabels.password'),
          type: 'password',
          class: ''
        },
        {
          name: 'password-reset-repeat',
          model: '',
          rules: 'required|min:10|password:@password-reset',
          label: this.$t('inputLabels.repeatPassword'),
          type: 'password',
          class: ''
        }
      ],
      loading: false
    };
  },
  methods: {
    async resetPassword() {
      try {
        // eslint-disable-next-line camelcase
        const new_password = this.inputFields[0].model
        // eslint-disable-next-line camelcase
        const re_new_password = this.inputFields[1].model

        this.loading = true;
        const payload = {
          new_password,
          re_new_password
        };
        await this.$store.dispatch('user/setPassword', payload);
        this.$snackbar({ content: this.$t('SetNewPassword.successMessage'), color: 'success' });
        this.$router.push('/');
      } catch (err) {
        this.$snackbar({
          content: `${this.$t('SetNewPassword.errorMessage')}: ${err.message}`,
          color: 'error'
        })
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped>
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}
</style>
